import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Observable, map, tap } from 'rxjs';

import { RouterFacade } from '@core/shared/data-access';
import { Pagination } from '@core/shared/domain';
import { RestoreScrollingDirective, getPageChangePagination } from '@core/shared/util';
import {
  AsideFiltersComponent,
  DropdownComponent,
  ListHeaderComponent,
  SelectOption,
  SpinnerComponent,
} from '@core/ui';
import { ConditionsFacade, ConditionsState } from '@mp/contract-management/conditions/data-access';
import { ConditionSort, ConditionsSearchRequest } from '@mp/contract-management/conditions/domain';
import {
  ConditionsFilterAreaComponent,
  ConditionsHeaderComponent,
  ConditionsListComponent,
  ConditionsSearchBarSearchEvent,
} from '@mp/contract-management/conditions/ui';
import { getSearchRequestFromQueryParams } from '@mp/contract-management/conditions/util';

import { ConditionsAsideFiltersSummaryPipe } from './conditions-aside-filters-summary.pipe';
import { CONDITIONS_SORT_FIELD_OPTIONS } from './conditions-sort-field-options';

@Component({
  selector: 'mpctm-conditions-overview',
  standalone: true,
  templateUrl: './conditions-overview.component.html',
  styleUrls: ['./conditions-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LetDirective,

    MatPaginatorModule,

    SpinnerComponent,
    ConditionsHeaderComponent,
    ConditionsListComponent,
    ListHeaderComponent,
    AsideFiltersComponent,
    ConditionsFilterAreaComponent,
    ConditionsAsideFiltersSummaryPipe,
    RestoreScrollingDirective,
    DropdownComponent,
  ],
})
export class ConditionsOverviewComponent {
  readonly conditionsState$: Observable<ConditionsState> = this.conditionsFacade.conditionsState$;

  readonly activatedRouteQueryParams$: Observable<Params> = this.route.queryParams;

  readonly conditionSortOptions: SelectOption<ConditionSort>[] = CONDITIONS_SORT_FIELD_OPTIONS;

  private readonly initialPaginationParams: Partial<Pagination> = { page: 1 };

  constructor(
    private readonly conditionsFacade: ConditionsFacade,
    private readonly routerFacade: RouterFacade,
    private readonly route: ActivatedRoute,
  ) {
    this.activatedRouteQueryParams$
      .pipe(
        map((queryParams) => getSearchRequestFromQueryParams(queryParams)),
        tap((searchRequest) => this.conditionsFacade.fetchConditions(searchRequest)),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  onFilterChange(searchRequest: ConditionsSearchRequest): void {
    this.updateFiltersAndResetPage({ ...searchRequest });
  }

  onFilterReset(): void {
    this.navigateWithQueryParams();
  }

  onSearch(searchRequest: ConditionsSearchRequest, searchEvent: ConditionsSearchBarSearchEvent): void {
    this.updateFiltersAndResetPage({ ...searchRequest, ...searchEvent });
  }

  onSortChange(searchRequest: ConditionsSearchRequest, sort: ConditionSort | undefined): void {
    if (!sort) {
      return;
    }

    this.updateFiltersAndResetPage({ ...searchRequest, sort });
  }

  onPageChange(searchRequest: ConditionsSearchRequest, pageEvent: PageEvent): void {
    this.navigateWithQueryParams({ ...searchRequest, ...getPageChangePagination(pageEvent) });
  }

  private updateFiltersAndResetPage(searchRequest: ConditionsSearchRequest): void {
    this.navigateWithQueryParams({ ...searchRequest, ...this.initialPaginationParams });
  }

  private navigateWithQueryParams(searchRequest?: ConditionsSearchRequest): void {
    this.routerFacade.navigate((router, route) =>
      router.navigate(['./'], {
        relativeTo: route,
        replaceUrl: true,
        queryParams: searchRequest,
      }),
    );
  }
}
